<template>
  <div class="news">
    <Card :articles="articles" />
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  components: {
    Card,
  },
  created() {
    let news = [];
    let data = [];
    data = this.$store.state.news;
    for (let i = 0; i <= 2; i++) {
      news.push(data.articles[i]);
    }

    this.articles = news;
  },
  data() {
    return {
      articles: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.news {
opacity: 0.8;
background: repeating-linear-gradient( -45deg, #44c7f704, #0d43e50f 700px, #ffffff 15px, #eeeeee 25px );
}
</style>
