<template>
  <v-container>
    <v-row>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, index) in data" :key="index">
          <v-expansion-panel-header> {{ item.title }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link v-for="(sub, index) in item.subtitle" :key="index" :to="sub.url" style="display:block; color:black; font-size: 13px;" class="mb-3">{{ sub.subtitle }}</router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <br />
    <br />
    <v-container class="footer">
      <!-- <v-img src="@/assets/technine.png" width="44vw" height="6vh" alt="테크나인 로고" style="object-fit: cover;" /> -->
      <img src="@/assets/technine.png" width="50%" height="50%" style="margin-left:-4%;"/>
      <v-row class="mt-4">테크나인 주식회사</v-row>
      <v-row>서울특별시 광진구 동일로 18길 107, 202호<br />(자양동, 센트라임빌딩)</v-row>
      <v-row>대표자 : 이호동</v-row>
      <v-row class="mb-1">사업자등록번호 : 349-81-00729</v-row>
      <v-row>ⓒ Copyright Tech9 All rights reserved since 2017</v-row>
    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "MobileFooter",
  data() {
    return {
      data: [
        {
          title: "Tech9",
          subtitle: [
            {
              subtitle: '테크나인',
              url: '/about'
            },
            {
              subtitle: '연혁 및 주요성과',
              url: '/history'
            },
            {
              subtitle: '파트너스',
              url: '/partners'
            }
          ]
        },
        {
          title: "솔루션",
          subtitle: [
            {
              subtitle: '지능형 무선 위험(산사태 등)감지 시스템',
              url: '/srds'
            },
            {
              subtitle: '지능형 산불감지 시스템',
              url: '/swfm'
            },
            {
              subtitle: '소나무 재선충병 AI 모니터링 시스템',
              url: '/nematode'
            }
          ]
        },
        {
          title: '보도자료',
          subtitle: [
            {
              subtitle: '보도자료',
              url: '/news'
            },
          ]
        },
        {
          title: '고객문의',
          subtitle: [
            {
              subtitle: '고객문의',
              url: '/contact'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  color: gray;
  position: relative;
  font-size: 14px;
  
}
</style>