<template>
  <div class="mobile-news">
    <div class="news-header">
        <p id="header">News</p>
        <p id="header-kr">테크나인의 최신 소식</p>
    </div>
    <div class="news-content">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="news in articles" :key="news.id">

          
          <img class="mobile-news-img" :src="news.img" :alt="news.header" />
          <div class="mobile-news-desc"> 
            <p class="desc-name">{{ news.header }}</p>
            <div class="desc-btn">
              <a style="z-index:9" :href="news.src">자세히 보기</a>
            </div>
          </div>


        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="news-router-btn">
        <router-link to="/news">소식 더보기</router-link>
    </div>
    <br> 
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.scss";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  watch: {
    '$store.state.news.articles': {
      handler(newValue) {
        let news = newValue.slice(0, 3);
        this.articles = news;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-news{
  height: 850px;
  background-image: linear-gradient( 45deg , rgba(175, 247, 255, 0.285),rgba(29, 0, 218, 0.159)); 
}

.news-header{
  padding-top: 20%;
  padding-right: 4%;
  text-align: right;

  #header{
    font-family: 'NanumSquareNeo-Variable', sans-serif;
    font-weight: 700;
    font-size: 1.4em;
    color: #454545; 
  }
  #header-kr{
    font-family: 'NanumSquareNeo-Variable', sans-serif;
    font-weight: 400;
    color: #454545; 
  }
}

.news-content{
  margin-left:4%;
  margin-top: 8%;
  border-radius: 5px;
  background-color: white;
  min-height: 500px;
  display: flex;
  justify-content: center;
  width: 92%;
  .mobile-news-desc {
    text-align: center;
    margin-top: 5%;
    padding: 2% 10% 2% 10%;
    p{
      font-family: 'NanumSquareNeo-Variable', sans-serif;
      font-size:1.4em;
    }
    .desc-btn{
      a{
      font-family: 'NanumSquareNeo-Variable', sans-serif;
      text-decoration: none;
    }
      margin-top: 5%;
    }
  }
  .mobile-news-img{
    display: block;
    margin: 0 auto; 
    border-radius: 5px;
    width: 90%;
    margin-top: 5%;
    max-height: 340px;
  }
}

.news-router-btn{
    background-color: #23adc6;
    border-radius: 5px;
    height: 35px;
    width: 90%;
    margin-left: 5%;
    margin-top:5%;
    text-align: center;
    align-content: center;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
    
    a{
      font-family: 'NanumSquareNeo-Variable', sans-serif;
      color: #fff;
      text-decoration: none;
      
    }
    
  }


</style>
