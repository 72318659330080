<template>
  <div class="promotion">
    <div class="title">
      <span>3대 산림재난 대응 솔루션 등 주요 솔루션</span>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide>
        <img
          src="@/assets/images/solutions/srds/srds_img.png"
          alt="배너"
          width="100%"
          height="50%"
        />
        <p class="content">지능형 무선 위험(산사태 등) 감지 솔루션</p>
        <p class="text1">
          <span class="underline"
            >국토부 스마트시티&조달청 국민재난 안전분야 혁신제품</span
          >
        </p>
        <p class="text2">
          <i>전국 어디서나 핸드폰 터지는 곳이면 바로 설치 가능! </i>
        </p>
        <router-link to="/srds" class="btn">자세히 보기</router-link>
      </swiper-slide>
      <swiper-slide>
        <img
          src="@/assets/images/solutions/swfm/swfm_img2.png"
          alt="배너"
          width="100%"
          height="50%"
        />
        <p class="content">지능형 산불감지 모니터링 확산방향 알림 시스템</p>
        <p class="text1">
          <span class="underline">특허 등록</span>
        </p>
        <p class="text2">
          <i>복합센서 및 사진 기반 AI 산불감지 및 확산방향 알림</i>
        </p>
        <router-link to="/swfm" class="btn">자세히 보기</router-link>
      </swiper-slide>
      <swiper-slide>
        <img
          src="@/assets/images/solutions/nematode/nematode_img2.png"
          alt="배너"
          width="100%"
          height="50%"
        />
        <p class="content">지능형 산림병해충 AI 모니터링 시스템</p>
        <p class="text1">
          <span class="underline">특허 출원</span>
        </p>
        <p class="text2"><i> 사진 기반 친환경 AI 모니터링</i></p>
        <router-link to="/swfm" class="btn">자세히 보기</router-link>
      </swiper-slide>
      <swiper-slide>
        <img
          src="@/assets/images/solutions/etc/water_img.png"
          alt="배너"
          width="100%"
          height="50%"
        />
        <p class="content">토양정보 기반 자동관수시스템</p>
        <p class="text1">
          <span class="underline">전국 200여 개소 설치 및 운영</span>
        </p>
        <p class="text2"><i>다양한 토양정보 수집 및 자동관수 제어</i></p>
        <router-link to="/etc" class="btn">자세히 보기</router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-prev" slot="button-prev">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="swiper-next" slot="button-next">
        <i class="fa-solid fa-arrow-right"></i>
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.scss";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swfm_url: "/swfm",
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 4000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.promotion {
  position: relative;
  height: 620px;
  // background-color: #f6f5ef;
  background-image: linear-gradient( 45deg , rgba(175, 247, 255, 0.285),rgba(29, 0, 218, 0.159)); 
  .title {
    padding-top: 2%;
    text-align: center;
    > span {
      font-family: 'S-CoreDream-3Light';
      color: #333333;
      font-size: 35px;
      font-weight: bold;
    }
  } 
}
@media (max-width: 1200px) {
  .swiper {
    width: calc(390px * 3 + 20px);
    position: absolute;
    top: 15%;
    left: 50%;
    margin-left: calc((390px * 3 + 20px) / -2);
    .swiper-slide {
      height: 450px;
      opacity: 0.5;
      transition: opacity 1s;
      position: relative;
      border-radius: 4px;;
      border: 1px dotted #d2d2d2;
      background-color: rgba(255, 255, 255, 0.7);
      text-align: center;
      img{
        border-radius: 5px;;
      }
      .content {
        font-family: 'NanumSquareNeo-Variable';
        margin-top: 25px;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 2%;
      }
      .text1 {
        font-size: 16px;
        .underline {
          font-family: 'NanumSquareNeo-Variable';
          position: relative;
        }
        .underline::after {
          content: "";
          width: 100%;
          height: 10px;
          background: #00c29548;
          position: absolute;
          display: inline-block;
          left: 0;
          bottom: 1px;
          z-index: -1;
        }
      }
      .text2 {
        font-size: 14px;
        i{
          font-family: 'S-CoreDream-3Light';
        }
      }
      .btn {
        font-family: 'NanumSquareNeo-Variable';
        position: absolute;
        border: 1px dotted #434343;
        color: #333;
        right: 0;
        left: 0;
        margin: auto;
        bottom: 2%;
        text-decoration: none;
        &:hover{
          border: none;
          background-color: #38b5cb;
          color: white;
        }
      }
    }
    .swiper-slide-active {
      opacity: 1;
    }
    .swiper-pagination {
      bottom: 40px;
      left: 0;
      right: 0;
    }
    .swiper-pagination .swiper-pagination-bullet {
      background-color: transparent;
      // background-image: url('@/assets/technine.png');
      width: 12px;
      height: 12px;
      margin-right: 6px;
      outline: none;
      background-color: black;
    }
    .swiper-pagination .swiper-pagination-bullet:last-child {
      margin-right: 0;
    }
    .swiper-pagination .swiper-pagination-bullet-active {
    }
    .swiper-prev,
    .swiper-next {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      position: absolute;
      top: 300px;
      z-index: 1;
      cursor: pointer;
      // outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s;
      background-color: #38b5cb;
      font-size: 1.4em;
      color: white
    }
    .swiper-prev {
      left: 70%;
      margin-left: -480px;
    }
    .swiper-next {
      right: 69%;
      margin-right: -480px;
    }
    .swiper-prev:hover,
    .swiper-next:hover {
      background-color: #333;
      color: #fff;
    }
  }
}
@media (min-width: 1200px) {
  .swiper {
    width: calc(519px * 3 + 20px);
    position: absolute;
    top: 15%;
    left: 50%;
    margin-left: calc((519px * 3 + 20px) / -2);
    .swiper-slide {
      height: 450px;
      opacity: 0.5;
      transition: opacity 1s;
      position: relative;
      border-radius: 4px;;
      border: 1px dotted #d2d2d2;
      background-color: rgba(255, 255, 255, 0.7);
      text-align: center;
      img{
        border-radius: 5px;;
      }
      .content {
        font-family: 'S-CoreDream-3Light';
        margin-top: 25px;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 2%;
      }
      .text1 {
        font-size: 18px;
        .underline {
          font-family: 'NanumSquareNeo-Variable';
          position: relative;
        }
        .underline::after {
          content: "";
          width: 100%;
          height: 10px;
          background: #00c29548;
          position: absolute;
          display: inline-block;
          left: 0;
          bottom: 1px;
          z-index: -1;
        }
      }
      .text2 {
        font-size: 15px;
        i{
          font-family: 'S-CoreDream-3Light';
        }
      }
      .btn {
        font-family: 'NanumSquareNeo-Variable';
        position: absolute;
        color: #333;
        border: 1px dotted #434343;
        right: 0;
        left: 0;
        margin: auto;
        bottom: 2%;
        text-decoration: none;
        &:hover{
          border: none;
          background-color: #38b5cb;
          color: white;
        }
      }
    }
    .swiper-slide-active {
      opacity: 1;
    }
    .swiper-pagination {
      bottom: 40px;
      left: 0;
      right: 0;
    }
    .swiper-pagination .swiper-pagination-bullet {
      background-color: transparent;
      // background-image: url('@/assets/technine.png');
      width: 12px;
      height: 12px;
      margin-right: 6px;
      outline: none;
      background-color: black;
    }
    .swiper-pagination .swiper-pagination-bullet:last-child {
      margin-right: 0;
    }
    .swiper-pagination .swiper-pagination-bullet-active {
    }
    .swiper-prev,
    .swiper-next {
      width: 45px;
      height: 45px;
      border: 1px dotted #eee;
      border-radius: 50%;
      position: absolute;
      top: 300px;
      z-index: 1;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s;
      background-color: #38b5cb;
      font-size: 1.4em;
      color: white
    }
    .swiper-prev {
      left: 60%;
      margin-left: -480px;
    }
    .swiper-next {
      right: 59%;
      margin-right: -480px;
    }
    .swiper-prev:hover,
    .swiper-next:hover {
      background-color: #333;
      color: #fff;
    }
  }

}

</style>