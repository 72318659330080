<template>
  <div class="section">
    <div class="inner">
      <div class="content">
        <div class="content__text">
          <span class="about">AIoT 혁신을 선도하는 기업</span>
          <p class="subtitle">
            테크나인은 저전력이동통신<span class="en-sub">(LPWAN) </span> 기반의 친환경 IoT 솔루션을 제공합니다. 
            별도의 전기와 네트워크 연결 없이 필요한 장소에 바로 
            설치하고 다양한 센서를 활용하는 <br>  
            자율형 사물인터넷<span class="en-sub">(Autonomous IoT) </span>
            솔루션 개발을<br> 
            주력으로 하는
            벤처입니다.
          </p>
        </div>
        <div class="summary">
          <div class="item">
            <p>10+</p>
            <span>Solutions</span>
          </div>
          <div class="item">
            <p>15+</p>
            <span>Partners</span>
          </div>
        </div>
      </div>
      <div class="image"></div>
    </div>
  </div>
</template>

<script>

import gsap from 'gsap';
import _ from 'lodash';

export default {
  components: {
  },
  mounted() {
    window.addEventListener(
      'scroll',
      _.throttle(() => {
        if (window.scrollY > 550) {
          gsap.to('.content__text', {
            opacity: 1,
            y: 40,
            duration: 1,
          });
          gsap.to('.summary', {
            opacity: 1,
            y: 40,
            duration: 1,
          });
        }
      }),
    );
  },
};
</script>

<style lang="scss" scoped>
.section {
  font-family: 'NanumSquareNeo-Variable', sans-serif;
  .inner {
    display: flex;
    height: 540px;
    .content {
      width: 520px;
      padding-top: 5%;
      .content__text {
        .about {
          font-family: 'S-CoreDream-3Light';
          font-size: 40px;
          font-weight: bolder;
        }
        .subtitle {
          font-size: 21px;
          margin-top: 18px;
          font-family: 'S-CoreDream-3Light';
          
          .en-sub{
            font-size: 15px;
            font-family: 'S-CoreDream-3Light';
            color: #33333397;
            font-weight: 800;
          }

        }
      }
      .summary {
        font-family: 'S-CoreDream-3Light';
        font-weight: bold;
        color: #333333;
        display: flex;
        .item {
          text-align: center;
          margin-right: 20px;
          padding: 40px 20px 40px 0px;
          border-right: 1.2px dotted #dbdbdb;
          p {
            font-size: 44px;
            font-family: 'S-CoreDream-3Light';
            span {
              font-family: 'SUITE-Regular';
              font-size: 24px;
            }
          }
        }
        .item:last-child {
          border-right: 0;
        }
      }
    }
    .image {
      position: relative;
      width: 700px;
      background-image: url('@/assets/51.png');
      background-size: cover;
    }
  }
}
</style>
