<template>
  <div>
	<a :href="youtubeUrl" class="badge_youtube" target="_blank"><img src="@/assets/images/logo/youtube.png" width="48" style="position: relative; left: 2%;"/><div class="txt">지능형 무선 위험 감지 시스템 홍보영상 보기</div></a>
	<a :href="newsroUrl" class="badge_gov" target="_blank"><img src="@/assets/images/partners/newsro.png" width="30" style="position: relative; left: 2%;"/><div class="txt">국가정보기간뉴스 'NEWSRO'와 함께합니다</div></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      youtubeUrl: 'https://www.youtube.com/watch?v=41o7nEgYA_w',
      govmarketUrl: 'https://ppi.g2b.go.kr:8914/sm/dm/sch/searchGoodsDetail.do?invGdsIdntNo=00011743',
	  newsroUrl: 'https://www.newsro.kr/'
    }
  }
}
</script>

<style scoped lang="scss">
.badge_youtube {
	// width: 60px;
	width: 210px;
	height: 60px;
	// border-radius: 50%;
	border-radius: 20px;
	position: fixed;
	right: 20px;
	bottom: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	background: white;
	border: 3px solid #f2f2f2;
	// transition: border-radius 0.5s ease, width 0.5s ease;
}
.badge_gov {
	// width: 60px;
	width: 210px;
	height: 60px;
	// border-radius: 50%;
	border-radius: 20px;
	position: fixed;
	right: 20px;
	bottom: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	background: white;
	border: 3px solid #f2f2f2;
	// transition: border-radius 0.5s ease, width 0.8s ease;
	text-decoration: none;
}
.txt {
	color: black;
	font-family: 'S-CoreDream-3Light';
	font-size: 14.6px;
	text-align: center;
	// opacity: 0;
}

@media (max-width: 1200px) {
	.badge_youtube, .badge_gov {
		width: 60px;
		border-radius: 50%;
		.icon {
			left: 0px;
		}
	}
	.txt {
		display: none;
	}


}


// .badge_youtube:hover, .badge_gov:hover {
// 	border-radius: 20px;
// 	width: 210px;
	// .icon {
	// 	left: 0px;
	// }
// 	.txt {  
// 		opacity: 1;
// 		transition: 1s linear;
		
// 	}
	
// }

</style>