<template>
  <div>
    <!-- Mobile -->
    <template v-if="$isMobile()" >
      <MobileMain />
      <MobileAbout />
      <MobileProduct />
      <MobileNews />
    </template>
    <!-- PC, Tablet -->
    <template v-else>
      <Main />
      <About />
      <Product />
      <News />
    </template>
  </div>
</template>

<script>


import Main from '@/components/Main.vue';
import About from '@/components/About.vue';
import Product from '@/components/Product.vue';
import News from '@/components/News.vue';

/* mobile */
import MobileMain from '@/components/Mobile/MMain.vue'
import MobileAbout from '@/components/Mobile/MAbout.vue'
import MobileProduct from '@/components/Mobile/MProduct.vue'
import MobileNews from '@/components/Mobile/MNews.vue'


export default {
  components: {
    MobileMain,
    MobileAbout, 
    MobileProduct,
    MobileNews,
    Main,
    About,
    Product,
    News,
  },
};
</script>
