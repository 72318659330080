<template>
  <div>
    <div class="mb-top-nav ">
      <router-link :to="'/main'" class="active"><img src="@/assets/images/logo/logo_tech9_c02.png" alt="Tech9" class="logo-image"/></router-link>
      <div id="nav-items" v-show="showLinks">
        
        <table class="mb-nav-table"> 
          <tr>
            <td colspan="4" class="table-header">
              <router-link :to="'/about'" @click.native="closeNavOnClick"> About TECH9 </router-link>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="table-content"><router-link :to="'/history'"  @click.native="closeNavOnClick"> 연혁 및 주요성과 </router-link></td>
            <td colspan="2" class="table-content"><router-link :to="'/partners'"  @click.native="closeNavOnClick"> 주요 고객사 </router-link></td>
          </tr>
          <tr>
            <td colspan="4" class="table-header"> <router-link :to="'/srds'"  @click.native="closeNavOnClick"> Solutions </router-link></td>
          </tr>
          <tr>
            <td class="table-content"><router-link :to="'/srds'" @click.native="closeNavOnClick"> 위험감지 </router-link></td>
            <td class="table-content"><router-link :to="'/swfm'" @click.native="closeNavOnClick"> 산불감지 </router-link></td>
            <td class="table-content"><router-link :to="'/nematode'" @click.native="closeNavOnClick"> 재선충 </router-link></td>
            <td class="table-content"><router-link :to="'/etc'" @click.native="closeNavOnClick"> 기타 솔루션 </router-link></td>
          </tr>
          <tr>
            <td colspan="4" class="table-header"> <router-link :to="'/news'"  @click.native="closeNavOnClick"> News </router-link></td>
          </tr>
          <tr>
            <td colspan="4" class="table-header"> <router-link :to="'/contact'"  @click.native="closeNavOnClick"> Contact Us </router-link></td>
          </tr>
        </table>
      </div>
      <a href="#" class="icon" @click.prevent="toggleLinks">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      showLinks: false
    }
  },
  methods: {
    toggleLinks() {
      this.showLinks = !this.showLinks; 
    },
    closeNavOnClick() {
      this.showLinks = false;
    },
  }
}
</script>
<style scoped>

.logo-image {
  max-width: 30%; /* 이미지가 부모 요소(<a> 태그)의 너비를 넘지 않도록 설정 */
  max-height: 70px; /* 이미지의 최대 높이를 100px로 제한 (원하는 크기로 변경 가능) */
}

.mb-top-nav {
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 999;
  width: 100%;
}

.mb-top-nav #nav-items {
  font-family: 'NanumSquareNeo-Variable', sans-serif;
  text-align:right;
  padding-right: 3%;
}

.mb-top-nav a {
  color: black;
  padding: 10px 6px;
  text-decoration: none;
  display: block;
}

.mb-top-nav a.icon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.4em;
  padding-bottom:1%;
  padding-top: 5.5%;
  color: #1b2547;
}

.mb-nav-table{
  float: right;
  padding-right: 3%;
  padding-left: 8%;
  background-color: rgba(255, 255, 255, 0.900);
  padding-bottom: 4%;
}

@media (max-width: 300px) {
  .mb-nav-table{
    padding-right: 0%;
    padding-left: 0%;
  }
  .table-content a{
    color: rgba(71, 71, 71, 0.801);
    font-weight: 600;
    font-size: 0.9em;
  }
}
.mb-top-nav a:hover {
  color: black;
}

.active {
  background-color:#eeeeee8f;
}
.table-header a{
  color: rgba(42, 42, 42, 0.866);
  font-family: 'NanumSquareNeo-Variable', sans-serif;
  font-size: 1.3em;
  font-weight: 800;
}
.table-content a
{
  color: rgba(71, 71, 71, 0.801);
  font-weight: 600;
  font-family: 'NanumSquareNeo-Variable', sans-serif;
}
</style>