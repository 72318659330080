<template>
  <div class="section">
    <div class="main">
      <div class="inner">
        <!-- <Popup v-if="this.openModal == true" @sendClose="closeModalView" /> -->
        <div class="title">
          <span>친환경 AIoT의 새로운 기준,</span>
          <div class="fade">
            <span>Tech9</span>
            <p style="fontSize: 19px;">⚡전기<span style="fontSize: 15px">(220V)</span> <img src="@/assets/wifi.png" width="20px;" height="18px;" style="display:inline-block;"/> 인터넷<span style="fontSize: 15px">(WiFi)</span> 필요없는 AIoT 솔루션 기업</p>
            <!-- <a
              href="https://www.youtube.com/watch?v=41o7nEgYA_w"
              class="btn"
              target="_blank"
              >지능형 위험감지(혁신제품) 소개 영상 보기 <i class="fa-solid fa-arrow-right"></i>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
// import Popup from '@/components/Popup.vue'

export default {
  components: {
    // Popup
  },
  mounted() {
    gsap.from('.fade', {
      opacity: 0,
      y: 100,
      duration: 2,
    });
  },
  data() {
    return {
      openModal: true
    }
  },
  methods: {
    closeModalView(data) {
      this.openModal = data
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  .main {
    background-image: url('@/assets/main.jpg');
    width: 100%;
    height: 1080px;
    position: relative;
    .inner {
      height: 1080px;
    }
    .title {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 475px;
      height: 100px;
      margin: auto 0;
      > span {
        color: #fff;
        font-size: 39px;
        font-family: 'S-CoreDream-3Light';
        font-weight: 800;
      }
      .fade {
        > span {
          color: #fff;
          font-size: 36px;
          font-family: 'SUITE-Regular';
          font-weight: 800;
        }
        > p {
          margin-top: 6px;
          color: #fff;
          font-family: 'S-CoreDream-3Light';
          font-weight: 800;
        }
        .btn {
          font-family: 'S-CoreDream-3Light';
          line-height: 36px;
          width: 380px;
          height: 60px;
          color: #fff;
          background-color: #38b5cb;
          font-size: 18px;
          border-radius: 7px;
          text-decoration: none;
          border: none;
          &:hover {
            background-color: white;
            color: #090e25e6;
            font-weight: 800;
        }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .section {
    .main {
      background-image: url('@/assets/main.jpg');
      width: 100%;
      height: 1080px;
      position: relative;
      .inner {
        width: 1024px;
        height: 1080px;
      }
      .title {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 455px;
        height: 100px;
        margin: auto 0;
        > span {
          color: #fff;
          font-size: 36px;
        }
        .fade {
          > span {
            color: #fff;
            font-size: 36px;
          }
          > p {
            margin-top: 6px;
            color: #fff;
          }
          .btn {
            font-family: Noto sans KR;
            line-height: 36px;
            width: 370px;
            height: 60px;
            color: #fff;
            background-color: #23adc6;
            font-size: 18px;
            font-weight: bold;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 1s;
}

.bounce-leave-active {
  animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
