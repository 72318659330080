<template>
  <v-app>
    <template v-if="$isMobile()">
    <!-- Mobile -->
      <MobileHeader />
      <transition name="fade">
        <RouterView />
      </transition>
      <MFooter />
    </template>
    <!-- Web -->
    <template v-else>
      <Header />
      <transition name="fade">
        <RouterView />
      </transition>
      <Footer />
      <Badge />
    </template>
  </v-app>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Badge from '@/components/Badge'
// Mobile
import MobileHeader from '@/components/Mobile/MobileHeader.vue'
import MFooter from '@/components/Mobile/MFooter.vue'

export default {
  name: 'App',

  components: {
    Header,
    Footer,
    MobileHeader,
    MFooter,
    Badge
  },

  data() {
    return {};
  },
};
</script>
<style>
@import '@/assets/style/main.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
