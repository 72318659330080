import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import gsap from 'gsap';
import { router } from '@/routes/';
import { store } from '@/store/';
import VueMobileDetection from 'vue-mobile-detection';
import './assets/font/fonts.css';

Vue.config.productionTip = false;

Vue.use(gsap);
Vue.use(VueMobileDetection);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
