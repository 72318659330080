import Vue from 'vue';
import VueRouter from 'vue-router';

import MainView from '@/views/MainView';

// import TechnineView from '@/views/TechnineView';
// import HistoryView from '@/views/HistoryView';
// import PartnersView from '@/views/PartnersView';

// import SrdsView from '@/views/SrdsView';
// import SwfmView from '@/views/SwfmView';
// import NematodeView from '@/views/NematodeView';
// import ETCView from '@/views/ETCView';

// import NewsView from '@/views/NewsView';
// import ContactUsView from '@/views/ContactUsView';

// import NotFound from '@/components/NotFound';

Vue.use(VueRouter);

export const router = new VueRouter({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/main',
    },
    {
      path: '/main',
      name: 'main',
      component: MainView,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import("@/views/TechnineView")
    },
    {
      path: '/history',
      name: 'history',
      component: () => import("@/views/HistoryView")
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import("@/views/PartnersView")
    },
    {
      path: '/srds',
      name: 'srds',
      component: () => import("@/views/SrdsView")
      // component: SrdsView,
    },
    {
      path: '/swfm',
      name: 'swfm',
      component: () => import("@/views/SwfmView")
      // component: SwfmView,
    },
    {
      path: '/nematode',
      name: 'nematode',
      component: () => import("@/views/NematodeView")
      // component: NematodeView,
    },
    {
      path: '/etc',
      name: 'etc',
      component: () => import("@/views/ETCView")
      // component: ETCView,
    },
    {
      path: '/news',
      name: 'news',
      component: () => import("@/views/NewsView")
      // component: NewsView,
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import("@/views/ContactUsView")
      // component: ContactUsView,
    },
    {
      path: '/:notFound(.*)',
      component: () => import("@/components/NotFound")
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
