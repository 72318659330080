<template>
  <div class="product-mobile-section">
      <div class="product-header">
        <p id="main-header">3대 산림재난 대응 솔루션 </p>
        <p>및 주요 솔루션</p>
      </div>

      <div class="product-content">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="product in productData" :key="product.id">
            <img class="mobile-slide-img" :src="product.src" :alt="product.slideName" />
            <div class="mobile-slide-desc"> 
              <p class="desc-name">{{ product.slideName }}</p>
              <p class="desc-desc1"><i class="fa-solid fa-check"></i>
                {{ product.slideDesc1 }}</p>
              <p class="desc-desc2"><i class="fa-solid fa-check"></i> 
                {{ product.slideDesc2 }}</p>
              <div class="desc-btn">
                <router-link :to="product.linkTo">자세히 보기</router-link>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.scss";



export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swfm_url: "/swfm",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      productData:[
        { 
          id: 1,
          src : require("@/assets/images/solutions/mobile/mobile_srds.png"),
          slideName: "지능형 무선 위험(산사태 등) 감지 솔루션",
          slideDesc1: "국토부 스마트시티&조달청 국민재난 안전분야 혁신제품",
          slideDesc2: "전국 어디서나 핸드폰 터지는 곳이면 바로 설치 가능!",
          linkTo : "/srds"
        },
        { 
          id: 2,
          src : require("@/assets/images/solutions/mobile/mobile_wildfire.png"),
          slideName: "지능형 산불감지 모니터링 확산방향 알림 시스템",
          slideDesc1: "중기부 지원 R&D 진행중",
          slideDesc2: "복합센서 및 사진 기반 AI 산불감지 및 확산방향 알림",
          linkTo : "/swfm"
        },
        { 
          id: 3,
          src : require("@/assets/images/solutions/mobile/mobile_pinewilt.png"),
          slideName: "지능형 소나무 재선충병 AI 모니터링 시스템",
          slideDesc1: "중기부 지원 R&D 진행중",
          slideDesc2: "사진 기반 친환경 AI 모니터",
          linkTo : "/nematode"
        },
        { 
          id: 4,
          src : require("@/assets/images/solutions/mobile/mobile_soil.png"),
          slideName: "토양정보 기반 자동관수시스템",
          slideDesc1: "전국 200여 개소 설치 및 운영",
          slideDesc2: "다양한 토양정보 수집 및 자동관수 제어",
          linkTo : "/etc"
        },
      ]
    };
  },

}
</script>
<style lang="scss"> 
.product-mobile-section{
  height: 850px;
  background-image: linear-gradient( 45deg,rgba(29, 0, 218, 0.159) , rgba(175, 247, 255, 0.285)); 
  padding-top: 18%;
}

.product-header{
  padding-right: 10%;
  text-align: right;

  p{
    font-family: 'NanumSquareNeo-Variable', sans-serif;
  }

  #main-header{
    font-size: 1.4em;
    font-weight: 800;
  }
}



.product-content{
  width:90%;
  background-color: #fff;
  border-radius: 10px;
  height: 80%;
  padding-top: 5%;
  margin-top: 2%;
  margin-left: 5%;
  padding-left:5%;
  border: 1px dotted #cccccc;
}

@media (max-width: 300px) {
  .product-content{
    height: 63%;
  }
}


.mobile-slide-img{
  border-radius: 5px;
  width:94%;
  height:100%;
  margin-top: 2%;
}

.mobile-slide-desc{
  width: 94%;
  text-align:center;
  padding-top: 2% 3% 2% 3%;

  .desc-name{
    padding: 1%;
    border-radius: 10px;
    font-size: 1.15em;
    margin-top: 3%;
  }
  .desc-desc1{
    font-size: 0.9em;
    margin-top: 1%;
  }
  .desc-desc2{
    margin-top: 1%;
    font-size: 0.9em;
  }
  .desc-btn{
    background-color: #23adc6f9;
    border-radius: 5px;
    height: 27px;
    width: 80%;
    margin-left: 10%;
    margin-top:5%;
    a{
      color: #fff;
      text-decoration: none;
      font-family: 'NanumSquareNeo-Variable', sans-serif;

    }
    
  }
  p{
    font-family: 'NanumSquareNeo-Variable', sans-serif;
  }
}
</style>


