<template>
  <div class="section">
    <div class="inner">
      <div class="top"></div>
      <div class="title"><h1>최신소식</h1><h4>Latest News</h4></div>
      <div class="Wrapper">
        <div v-for="(n, index) in articles" :key="index" class="news">
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto"
              color="grey lighten-4"
              max-width="340"
              height="374"
              align="left"
            >
              <v-img :aspect-ratio="4 / 3" :src="n.img">
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal white--text"
                    style="height: 100%"
                    id="hover-desc"
                  >
                    <p>{{ n.desc }}</p>
                  </div>
                </v-expand-transition>
              </v-img>
              <v-card-text class="pt-3" style="position: relative">
                <v-btn
                  :href="n.src"
                  target="_blank"
                  absolute
                  color="#0b1a44"
                  class="white--text"
                  fab
                  medium
                  right
                  top
                  id="moreBtn"
                >
                  <v-icon class="ma-3">mdi-newspaper-variant-outline</v-icon>
                </v-btn>
                <div class="mb-2">
                  <span id="issued-date"> {{ n.date }} </span>
                </div>

                <div class="mb-3" id="headline" v-html="n.header"></div>
              </v-card-text>
            </v-card>
          </v-hover>
        </div>
      </div>
      <router-link to="/news" class="btn">소식 더보기</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  height: 620px;
  text-align: center;
  .top {
    height: 20px;
  }
  > .title > h1 {
    font-family: 'S-CoreDream-3Light';
    font-size: 35px;
  }
  > .title > h4 {
    font-family: 'S-CoreDream-3Light';
    font-size: 20px;
    color: #8888885b;
    font-weight: 800;
  }
  .Wrapper {
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
    display: flex;
  }
  .btn {
    font-family: 'S-CoreDream-3Light';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 3%;
    margin: auto;
    text-decoration: none;
    border:none;
    background-color: #091536;
    margin-bottom: 1%;

    color: white;
    &:hover{
      border: none;
      background-color: #38b5cb;
      border: 1px dotted #eee;
    }
  }
}
#hover-desc {
  height: 100%;
  overflow: auto;
  text-align: left;

  p {
    margin: 5%;
    font-family: 'S-CoreDream-3Light';
  }
  @media (max-height: 828px) {
    font-size: 15px;
    font-family: 'S-CoreDream-3Light';
  }
}
.v-card--reveal {
  padding: 1% 3% 1% 3%;
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  font-size: 18px;
  background-color: #0b1a44eb;
}   

#issued-date{
  font-family: 'S-CoreDream-3Light';
  font-weight: 600;
}

#headline{
  font-family: 'S-CoreDream-3Light';
  font-weight: 800;
  font-size: 1.1em;
}

</style>