
<template>
  <div class="mobile-section">
    <div class="main">
      <div class="inner">
        <div class="title">
          <span>친환경 IoT의 <br> 새로운 기준,</span>
          <div class="fade">
            <span>Tech9</span>
            <!-- <p>저전력 이동통신 기반의 친환경 AIoT 솔루션 기업</p> -->
            <p>⚡전기<span style="fontSize: 14px">(220V)</span> 🛜인터넷<span style="fontSize: 14px">(WiFi)<br /></span> 필요없는 솔루션 기업</p>
            <a
              href="https://www.youtube.com/watch?v=41o7nEgYA_w"
              class="btn"
              target="_blank"
              >지능형 위험감지(혁신제품) <br />소개 영상 보기 <i class="fa-solid fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {

};
</script>


<style lang="scss" scoped>
.mobile-section{
    height: 850px;
    font-family: 'S-CoreDream-3Light',sans-serif;

    .main {
    background-image: url('@/assets/main.jpg');
    width: 100%;
    height: 100%;
    position: relative;
    .inner {
      
      width: 100%;
      height: 100%;
    }
    .title {
      position: relative;
      top: 30%;
      bottom: 0;
      width: 90%;
      height: 22%;
      margin: auto 0;
      margin-left: 3%;
      > span {
        color: #fff;
        font-family: 'S-CoreDream-3Light',sans-serif;
        font-size: 36px;
        font-weight: bold;

      }
      .fade {
        > span {
          color: #fff;
          font-size: 36px;
          font-family: 'NanumSquareNeo-Variable', sans-serif;
          font-weight: bold;
        }
        > p {
          margin-top: 3%;
          margin-bottom: 2%;
          color: #fff;
          font-size: 1em;
          font-family: 'S-CoreDream-3Light', Serif;        
        }

        .btn {
          margin-top: 20%;
          line-height: 34px;
          width: 80%;
          height: 10%;
          color: #fff;
          background-color: #23adc6f9;
          border: 1.2px dotted #eee ;
          font-size: 1em;
          font-weight: bold;
          border-radius: 7px;
          font-family: 'S-CoreDream-3Light',sans-serif;
        }
        @media (max-width: 300px) {
            .btn{
              width: 90%;
            }
          }
      }
    }
  }
  }




</style>