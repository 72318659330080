<template>
  <div class="mobile-section">
    <v-container>
      <div class="row" id="top-row">
        <div class="col"> 
          <div class="about-header"> About Us</div>
          <img src="@/assets/51.png">
        </div>
      </div>
      <div class="row">
        <div class="col" id="about-content">
          <span class="about">AIoT 혁신을 선도하는 기업</span>
          <p class="subtitle">
              테크나인은 저전력이동통신(LPWAN) 기반의 친환경 IoT 솔루션을
              제공합니다. 별도의 전기와 네트웍 연결 없이 
              필요한 장소에 바로 설치하고 다양한 센서를 활용하는 자율형
              사물인터넷(Autonomous IoT) 솔루션 개발을 주력으로 하는
              벤처입니다.
          </p>
          <div class="mobile-summary">
            <div class="item">
              <div class="number">10+</div>
              <p>Solutions</p>
            </div>
            <div class="item">
              <div class="number">15+</div>
              <p>Partners</p>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import gsap from 'gsap';
import _ from 'lodash';
export default {
  mounted() {
    window.addEventListener(
      'scroll',
      _.throttle(() => {
        if (window.scrollY > 550) {
          gsap.to('.content__text', {
            opacity: 1,
            y: 40,
            duration: 1,
          });
          gsap.to('.summary', {
            opacity: 1,
            y: 40,
            duration: 1,
          });
        }
      }),
    );
  },
};
</script>


<style lang="scss" scoped>
.mobile-section{
  height: 850px;
  background-image: linear-gradient( 45deg, rgba(175, 247, 255, 0.285), rgba(29, 0, 218, 0.159) ); 
/*   background-color: #a0abc313; */

  #top-row{
      text-align: center;
      align-items: center;
      vertical-align: middle;
      height: 40%;

    }
    @media (max-width: 300px) {
      #top-row {
          height: 30%;
        }
      }
  .about-header{
    position: relative;
    padding-top: 10%;
    width: 100%;
    height:30%;
    min-height:100px;
    font-family: 'NanumSquareNeo-Variable', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.4em;
    font-weight: 800;
    color: #45454553;
    
  }

  .row {
    display: flex;
    color: #454545;   
    img{
      width:94%;
      display: inline;
      border-radius: 7%;;
      margin-top: 2%;
    }

    #about-content{
      margin: 18% 4% 1% 4%;
      
      .about{
        font-size: 1.8rem;
        font-weight: 500;
        font-family: 'NanumSquareNeo-Variable', sans-serif;
      }
      p{
        margin : 4% 5% 1% 0%;
        font-size: 1rem;
        font-family: 'S-CoreDream-3Light',sans-serif;

      }
      @media (max-width: 300px) {
        .about {
          font-size: 1.3rem;
        }
      }
    }
    .mobile-summary{
      display: flex;
      text-align: center;
      justify-content: center;

      .item{
        margin: 6%;

        .number{
          background-color: #fff;
          border: 1px dotted #9f9f9f;
          font-size: 2.6em;
          border-radius: 100%;
          width: 100px;
          height: 100px;
          display: inline-grid;
          align-content: space-around; 
        }
        
      }

    }

  //   .content {
  //     width: 100%;
  //     padding-top: 10%;
  //     .content__text {
  //       border:1px solid blue;

  //       img{
  //         border: 1px solid red;
  //         width: 70%;
  //       }

  //       .about-image{
  //           width: 50%;
  //           height: 250px;
  //           background-image: url('@/assets/51.png');
  //           background-size: cover;
            
  // }
  //       .about {
  //         font-size: 40px;
  //         font-weight: bold;
  //       }
  //       .subtitle {
  //         font-size: 20px;
  //         margin-top: 18px;
  //       }
  //     }
  //     .summary {
  //       font-weight: bold;
  //       .item {
  //         text-align: center;
  //         margin-right: 20px;
  //         padding: 40px 20px 40px 0px;
  //         border-right: 1px solid #dbdbdb;
  //         p {
  //           font-size: 44px;
  //           span {
  //             font-size: 24px;
  //           }
  //         }
  //       }
  //       .item:last-child {
  //         border-right: 0;
  //       }
  //     }
  //   }

  }
}

</style>
