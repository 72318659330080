<template>
  <div class="footer">
    <div class="inner">
      <div class="sitemap">
        <div class="left">
          <div class="title" v-for="(item, index) in main" :key="index">
            <router-link :to="item.url">{{ item.title }}</router-link>
          </div>
        </div>
        <div class="right">
          <div
            v-for="(item, index) in sub"
            :key="index"
            class="subtitleWrapper"
          >
            <div
              v-for="(subtitle, index) in item"
              :key="index"
              class="subtitle"
            >
              <router-link :to="subtitle.url" v-html="subtitle.name"></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="infomationWrapper">
        <div class="infomation">
          <p>회사명 | 테크나인 주식회사</p>
          <p>대표자 | 이호동</p>
          <p>사업자 등록번호 | 349-81-00729</p>
          <p>
            주소 |
            <span>서울특별시 광진구 동일로 18길 107, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;202호(자양동, 센트라임빌딩)</span>
          </p>
          <p>대표메일 | contact@tech9.kr</p>
          <p>ⓒ Copyright Tech9 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
            >All rights reserved since 2017</span> </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
export default {
  extends: Header,
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #090e25e6;
  .inner {
    display: flex;
    border-bottom: 1px solid #fff;
    .sitemap {
      display: flex;
      width: 70%;
      .left {
        border-right: 0.5px dotted #eeeeee5e;
        margin-right: 3%;
        padding-right: 5%;
        .title {
          width: 100px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          a {
            color: white;
            font-family: 'S-CoreDream-3Light';
            text-decoration: none; 
            &:hover{
              background-color: white;
              color: #090e25e6;
            }

          }

        }
      }
      .right {
        .subtitleWrapper {
          display: flex;
          .subtitle {
            padding-top: 22px;
            width: 160.5px;
            height: 60px;
            text-align: center;
            font-size: 14px;
            a {
              color: white;
              font-family: 'S-CoreDream-3Light';
              text-decoration: none; 
              &:hover{
                background-color: white;
                color: #090e25e6;
              }
            }
          }
        }
      }
    }
    .infomationWrapper {
      position: relative;
      width: 30%;
      .infomation {
        border-left: 0.5px dotted #eeeeee5e;
        padding: 3% 3% 3% 15%;
        font-size: 15px;
        p{
          color: white;
          font-family: 'S-CoreDream-3Light';
          span{
            font-family: 'S-CoreDream-3Light';
          }
        }
      }
    }
  }
}
</style>
